// src/app/[local]/home/BlockchainExpertiseSection.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const blockchainExpertiseStyle = css`
  background-color: #e8f4f8; // Utilisez une couleur de fond appropriée
  padding: 40px;
  text-align: center;

  h2 {
    color: #2a5885; // Utilisez une couleur de titre appropriée
    margin-bottom: 20px;
  }

  p {
    color: #333333; // Utilisez une couleur pour le texte du paragraphe
    line-height: 1.6;
  }

  // Ajoutez d'autres styles au besoin
`;

const BlockchainExpertiseSection = () => {
  return (
    <section css={blockchainExpertiseStyle}>
      <h2>Spécialiste Blockchain, Crypto & NFTs</h2>
      <p>
        Dans le monde en constante évolution de la blockchain, des cryptomonnaies et des NFTs, je reste à la pointe de la technologie pour offrir des services
        innovants et pertinents. Que vous cherchiez à intégrer des fonctionnalités liées à la blockchain ou à explorer l&apos;univers des NFTs, je suis là pour
        transformer vos idées en réalité.
      </p>
    </section>
  );
};

export default BlockchainExpertiseSection;
