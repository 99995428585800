// src/app/[local]/home/HeroSection.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { css, ClassNames } from '@emotion/react';
import dynamic from 'next/dynamic';
import { useTranslations } from 'next-intl';
import { FC, ReactElement } from 'react';

const RetroCosmosBackground = dynamic(() => import('@/img/background/RetroCosmosBackground'), {
  ssr: false,
  loading: () => <div>Loading background...</div>,
});

const SocialBar = dynamic(() => import('@/server/contact/social/SocialBar'), {
  ssr: false,
  loading: () => <div>Loading icons...</div>,
});

const sectionStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
  background: linear-gradient(180deg, rgba(21, 11, 49, 0.76) 0%, rgba(13, 5, 29, 0.88) 50%, rgba(10, 3, 18, 1) 100%);
  color: #ffffff;

  .socialIcons {
    display: flex;
    max-width: 15rem;
    margin: 25px auto;
    gap: 10px;
    justify-content: center;
  }

  @media (min-width: 769px) {
    min-height: 40vh;
  }
`;

const contentStyle = css`
  position: absolute;
  top: 5%;
  z-index: 2;
  padding: 0 20px;
  text-align: center;

  @media (min-width: 769px) {
    top: 15%;
    text-align: left;
  }
`;

const HeroSection: FC = (): ReactElement => {
  const t = useTranslations('hero');

  return (
    <ClassNames>
      {({ css }) => (
        <section className={css(sectionStyle)} aria-labelledby='hero-heading'>
          <RetroCosmosBackground />
          <div className={css(contentStyle)}>
            <h1 id='hero-heading'>{t('heading')}</h1>
            <p>{t('subheading')}</p>
          </div>
          <div className='socialIcons svgIcon'>
            <SocialBar company='DEAGLE' />
          </div>
          <h2>{t('socialHeading')}</h2>
        </section>
      )}
    </ClassNames>
  );
};

export default HeroSection;
