import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\node_modules\\next-intl\\dist\\esm\\shared\\NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\app\\[locale]\\index\\bloc\\BlockchainExpertiseSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\app\\[locale]\\index\\bloc\\HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\app\\[locale]\\index\\bloc\\ShowcaseSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\app\\[locale]\\index\\bloc\\StorySection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\prod\\anton\\06-29-anton-prod-ok\\src\\core\\util\\WebVitalsClient.tsx");
