// src/core/util/reportWebVitals.ts
import type { Metric } from 'web-vitals';

const reportWebVitals = (metric: Metric) => {
  console.log(metric);
  // Vous pouvez envoyer les métriques à une analyse externe ici
  // Par exemple : fetch('/analytics', { method: 'POST', body: JSON.stringify(metric) });
};

export default reportWebVitals;
