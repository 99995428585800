// src/app/[local]/home/ShowcaseSection.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const showcaseStyle = css`
  background-color: #f5f5f5; // Choisir une couleur adaptée
  padding: 20px;
  text-align: center;

  h2 {
    color: #333; // Choisir une couleur de texte
    margin-bottom: 10px;
  }

  p {
    color: #666; // Choisir une couleur pour le paragraphe
    line-height: 1.6;
  }

  // Ajouter des styles supplémentaires selon besoin
`;

const ShowcaseSection = () => {
  return (
    <section css={showcaseStyle}>
      <h2>Anton Yohan - Création de Sites Vitrines Optimisés et Solutions Web Full Stack</h2>
      <p>
        Bienvenue sur AntonYohan.com, votre partenaire expert en développement web, spécialisé dans la création de sites vitrines hautement optimisés pour le
        SEO et les performances. Avec une approche centrée sur l&apos;innovation et la qualité, je m&apos;engage à développer des solutions web qui ne se
        contentent pas de répondre à vos besoins, mais les anticipent.
      </p>
    </section>
  );
};

export default ShowcaseSection;
