// src/core/util/WebVitalsClient.tsx
'use client';
import { useEffect } from 'react';
import { onCLS, onFID, onLCP, onTTFB } from 'web-vitals';
import reportWebVitals from './reportWebVitals';

const WebVitalsClient = () => {
  useEffect(() => {
    onCLS(reportWebVitals);
    onFID(reportWebVitals);
    onLCP(reportWebVitals);
    onTTFB(reportWebVitals);
  }, []);

  return null;
};

export default WebVitalsClient;
