// src/app/[local]/home/StorySection.jsx
'use client';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const sectionStyle = css`
  position: relative;
  display: flex;
  background: linear-gradient(180deg, rgba(21, 11, 49, 0.76) 0%, rgba(13, 5, 29, 0.88) 50%, rgba(10, 3, 18, 1) 100%);
  color: #ffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 30vh;

  .socialIcons {
    display: flex;
    width: 100%;
    max-width: 15rem;
    margin: 25px auto;
    gap: 10px;
    justify-content: center;
  }

  // Media query pour les écrans plus grands que 768px
  @media (min-width: 769px) {
    min-height: 50vh;
  }
`;

const StorySection = () => {
  return (
    <section css={sectionStyle}>
      <div>
        <h2>Webmaster et Développeur Next.js</h2>
        <p>
          Passionné par le développement web moderne, je crée des expériences utilisateur dynamiques et performantes. Avec une expertise en Next.js et
          @emotion/react, je transforme les idées en réalités numériques engageantes.
        </p>
        <p>
          Découvrez mes projets, mes compétences en codage et mon parcours unique dans le monde du développement web. Mon portfolio est le reflet de mon
          dévouement à l&apos;innovation et à l&apos;excellence technique.
        </p>
        <p>Intéressé par une collaboration ? Contactez-moi pour discuter de comment nous pouvons créer ensemble l&apos;avenir du web.</p>
      </div>
    </section>
  );
};

export default StorySection;
